// @file `requestIdleCallback` and `cancelIdleCallback` polyfill

/**
 * Queues a function to be called during a browser's idle periods.
 * @param callback A reference to a function that should be called in the near future, when the event loop is idle.
 * @returns An ID which can be used to cancel the callback by passing it into the `cancelIdleCallback` function.
 */
const requestIdleCallback = (callback: () => void): number => {
  if (window.requestIdleCallback !== undefined) {
    return window.requestIdleCallback(callback)
  }
  // Safari still doesn't support `requestIdleCallback` yet.
  return window.setTimeout(callback, 0)
}

/**
 * Cancels a callback previously scheduled with `requestIdleCallback`.
 * @param id The ID value returned by `requestIdleCallback`.
 */
const cancelIdleCallback = (id: number): void => {
  if (window.cancelIdleCallback !== undefined) {
    window.cancelIdleCallback(id)
  } else {
    window.clearTimeout(id)
  }
}

export { cancelIdleCallback, requestIdleCallback }
